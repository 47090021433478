import React from 'react';

export default function PrivacyPolicy() {
  return (
    <section
      id="om"
      className="min-h-screen bg-white px-5 py-12 md:px-[60px] md:py-16 xlg:px-[330px] mt-[138px] xl:mt-[146px]"
    >
      <div className="flex flex-col gap-y-12 md:gap-y-16">
        <legend className="text-[32px] md:text-5xl font-bold text-[#19233F]">
          Privacy Policy
        </legend>

        <div className="flex flex-col gap-y-8">
          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">Purpose</h3>
            <p className="text-base font-medium text-[#484352]">
              The purpose of this privacy policy (this `Privacy Policy`) is to
              inform users of our Site of the following:
            </p>
            <ol
              type="1"
              start={1}
              className="flex flex-col gap-y-3 list-decimal pl-6"
            >
              <li className="text-base font-medium text-[#484352]">
                The personal data we will collect;
              </li>
              <li className="text-base font-medium text-[#484352]">
                Use of collected data;
              </li>
              <li className="text-base font-medium text-[#484352]">
                Who has access to the data collected;
              </li>
              <li className="text-base font-medium text-[#484352]">
                The rights of Site users; and
              </li>
              <li className="text-base font-medium text-[#484352]">
                The {`Site's`} cookie policy.
              </li>
            </ol>
            <p className="text-base font-medium text-[#484352]">
              This Privacy Policy applies in addition to the terms and
              conditions of our Site.
            </p>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">Consent</h3>
            <p className="text-base font-medium text-[#484352]">
              By using our Site users agree that they consent to:
            </p>
            <ol
              type="1"
              start={1}
              className="flex flex-col gap-y-3 list-decimal pl-6"
            >
              <li className="text-base font-medium text-[#484352]">
                The conditions set out in this Privacy Policy; and
              </li>
              <li className="text-base font-medium text-[#484352]">
                The collection, use, and retention of the data listed in this
                Privacy Policy.
              </li>
            </ol>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              Personal Data We Collect
            </h3>
            <p className="text-base font-medium text-[#484352]">
              We only collect data that helps us achieve the purpose set out in
              this Privacy Policy. We will not collect any additional data
              beyond the data listed below without notifying you first.
            </p>

            <h3 className="text-base font-medium text-[#020C01]">
              Data Collected Automatically
            </h3>
            <p className="text-base font-medium text-[#484352]">
              When you visit and use our Site, we may automatically collect and
              store the following information:
            </p>
            <ol
              type="1"
              start={1}
              className="flex flex-col gap-y-3 list-decimal pl-6"
            >
              <li className="text-base font-medium text-[#484352]">
                IP address;
              </li>
              <li className="text-base font-medium text-[#484352]">
                Location;
              </li>
              <li className="text-base font-medium text-[#484352]">
                Hardware and software details;
              </li>
              <li className="text-base font-medium text-[#484352]">
                Clicked links; and
              </li>
              <li className="text-base font-medium text-[#484352]">
                Content viewed.
              </li>
            </ol>
            <h3 className="text-base font-medium text-[#020C01]">
              Data Collected in a Non-Automatic Way
            </h3>
            <p className="text-base font-medium text-[#484352]">
              We may also collect the following data when you perform certain
              functions on our Site:
            </p>
            <ol
              type="1"
              start={1}
              className="flex flex-col gap-y-3 list-decimal pl-6"
            >
              <li className="text-base font-medium text-[#484352]">
                First and last name;
              </li>
              <li className="text-base font-medium text-[#484352]">Age;</li>
              <li className="text-base font-medium text-[#484352]">
                Date of birth;
              </li>
              <li className="text-base font-medium text-[#484352]">Sex;</li>
              <li className="text-base font-medium text-[#484352]">
                Email address;
              </li>
              <li className="text-base font-medium text-[#484352]">
                Phone number;
              </li>
              <li className="text-base font-medium text-[#484352]">Address;</li>
              <li className="text-base font-medium text-[#484352]">
                Payment information;
              </li>
              <li className="text-base font-medium text-[#484352]">
                Auto fill data; and
              </li>
              <li className="text-base font-medium text-[#484352]">
                Microbiome data, health records.
              </li>
            </ol>

            <p className="text-base font-medium text-[#484352]">
              This data may be collected using the following methods:
            </p>
            <ol
              type="1"
              start={1}
              className="flex flex-col gap-y-3 list-decimal pl-6"
            >
              <li className="text-base font-medium text-[#484352]">
                Creating an account.
              </li>
            </ol>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              How We Use Personal Data
            </h3>
            <p className="text-base font-medium text-[#484352]">
              Data collected on our Site will only be used for the purposes
              specified in this Privacy Policy or indicated on the relevant
              pages of our Site. We will not use your data beyond what we
              disclose in this Privacy Policy.
            </p>
            <p className="text-base font-medium text-[#484352]">
              The data we collect automatically is used for the following
              purposes:
            </p>
            <ol
              type="1"
              start={1}
              className="flex flex-col gap-y-3 list-decimal pl-6"
            >
              <li className="text-base font-medium text-[#484352]">
                In-house analytics and statistics
              </li>
            </ol>

            <p className="text-base font-medium text-[#484352]">
              The data we collect when the user performs certain functions may
              be used for the following purposes:
            </p>
            <ol
              type="1"
              start={1}
              className="flex flex-col gap-y-3 list-decimal pl-6"
            >
              <li className="text-base font-medium text-[#484352]">
                Communication and research purposes.
              </li>
            </ol>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              Who We Share Personal Data With
            </h3>
            <h3 className="text-base font-medium text-[#020C01]">Employees</h3>
            <p className="text-base font-medium text-[#484352]">
              We may disclose user data to any member of our organization who
              reasonably needs access to user data to achieve the purposes set
              out in this Privacy Policy.
            </p>
            <h3 className="text-base font-medium text-[#020C01]">
              Third Parties
            </h3>
            <p className="text-base font-medium text-[#484352]">
              We may share user data with the following third parties:
            </p>
            <ol
              type="1"
              start={1}
              className="flex flex-col gap-y-3 list-decimal pl-6"
            >
              <li className="text-base font-medium text-[#484352]">
                Payment processing company;
              </li>
              <li className="text-base font-medium text-[#484352]">
                Health and Wellness company; and
              </li>
              <li className="text-base font-medium text-[#484352]">
                EHR and Engagement platform.
              </li>
            </ol>
            <p className="text-base font-medium text-[#484352]">
              We may share the following user data with third parties:
            </p>
            <ol
              type="1"
              start={1}
              className="flex flex-col gap-y-3 list-decimal pl-6"
            >
              <li className="text-base font-medium text-[#484352]">
                Payment card information; and
              </li>
              <li className="text-base font-medium text-[#484352]">
                Health records.
              </li>
            </ol>
            <p className="text-base font-medium text-[#484352]">
              We may share user data with third parties for the following
              purposes:
            </p>
            <ol
              type="1"
              start={1}
              className="flex flex-col gap-y-3 list-decimal pl-6"
            >
              <li className="text-base font-medium text-[#484352]">
                Payment processing; and
              </li>
              <li className="text-base font-medium text-[#484352]">
                Health and wellness purposes.
              </li>
            </ol>

            <p className="text-base font-medium text-[#484352]">
              Third parties will not be able to access user data beyond what is
              reasonably necessary to achieve the given purpose.
            </p>

            <h3 className="text-base font-medium text-[#020C01]">
              Other Disclosures
            </h3>
            <ol
              type="1"
              start={1}
              className="flex flex-col gap-y-3 list-decimal pl-6"
            >
              <li className="text-base font-medium text-[#484352]">
                If the law requires it;
              </li>
              <li className="text-base font-medium text-[#484352]">
                If it is required for any legal proceeding;
              </li>
              <li className="text-base font-medium text-[#484352]">
                To prove or protect our legal rights; and
              </li>
              <li className="text-base font-medium text-[#484352]">
                To buyers or potential buyers of this company in the event that
                we seek to sell the company.
              </li>
            </ol>

            <p className="text-base font-medium text-[#484352]">
              If you follow hyperlinks from our Site to another Site, please
              note that we are not responsible for and have no control over
              their privacy policies and practices.
            </p>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              How Long We Store Personal Data
            </h3>
            <p className="text-base font-medium text-[#484352]">
              User data will be stored until the purpose the data was collected
              for has been achieved. You will be notified if your data is kept
              for longer than this period.
            </p>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              How We Protect Your Personal Data
            </h3>
            <p className="text-base font-medium text-[#484352]">
              In order to protect your security, we use the strongest available
              browser encryption and store all of our data on servers in secure
              facilities. All data is only accessible to specific company
              employees and licensed health professionals. Our employees are
              bound by strict confidentiality agreements and a breach of this
              agreement would result in the {`employee's`} termination.
              <br />
              <br />
              While we take all reasonable precautions to ensure that user data
              is secure and that users are protected, there always remains the
              risk of harm. The Internet as a whole can be insecure at times and
              therefore we are unable to guarantee the security of user data
              beyond what is reasonably practical.
            </p>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">Children</h3>
            <p className="text-base font-medium text-[#484352]">
              We do not knowingly collect or use personal data from children
              under 13 years of age. If we learn that we have collected personal
              data from a child under 13 years of age, the personal data will be
              deleted as soon as possible. If a child under 13 years of age has
              provided us with personal data their parent or guardian may
              contact our privacy officer.
            </p>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              How to Access, Modify, Delete, or Challenge the Data Collected
            </h3>
            <p className="text-base font-medium text-[#484352]">
              If you would like to know if we have collected your personal data,
              how we have used your personal data, if we have disclosed your
              personal data and to who we disclosed your personal data, or if
              you would like your data to be deleted or modified in any way,
              please contact our privacy officer here{` `}
              <a
                href="mailto:hello@vitract.com"
                className="underline text-[#FF713F]"
              >
                hello@vitract.com
              </a>
            </p>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              Do Not Track Notice
            </h3>
            <p className="text-base font-medium text-[#484352]">
              Do Not Track {`("DNT")`} is a privacy preference that you can set
              in certain web browsers. We do not track the users of our Site
              over time and across third party websites and therefore do not
              respond to browser-initiated DNT signals. We are not responsible
              for and cannot guarantee how any third parties who interact with
              our Site and your data will respond to DNT signals.
            </p>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              How to Opt-Out of Data Collection, Use or Disclosure
            </h3>
            <p className="text-base font-medium text-[#484352]">
              In addition to the method(s) described in the{` `}
              <i>
                How to Access, Modify, Delete, or Challenge the Data Collected
              </i>
              {` `}
              section, we provide the following specific opt-out methods for the
              forms of collection, use, or disclosure of your personal data
              specified below:
            </p>
            <ol
              type="1"
              start={1}
              className="flex flex-col gap-y-3 list-decimal pl-6"
            >
              <li className="text-base font-medium text-[#484352]">
                You can opt out of the use of your personal data for marketing
                emails. You can opt-out by clicking {`"unsubscribe"`} at the
                bottom of any marketing email.
              </li>
            </ol>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              Cookie Policy
            </h3>
            <p className="text-base font-medium text-[#484352]">
              A cookie is a small file, stored on a {`user's`} hard drive by a
              website. Its purpose is to collect data relating to the {`user's`}
              browsing habits. You can choose to be notified each time a cookie
              is transmitted. You can also choose to disable cookies entirely in
              your internet browser, but this may decrease the quality of your
              user experience.
              <br />
              We use the following types of cookies on our Site:
            </p>
            <ol
              type="1"
              start={1}
              className="flex flex-col gap-y-3 list-decimal pl-6"
            >
              <li className="text-base font-medium text-[#484352]">
                <h3>Functional Cookies</h3>Functional cookies are used to
                remember the selections you make on our Site so that your
                selections are saved for your next visits;
              </li>
              <li className="text-base font-medium text-[#484352]">
                <h3>Analytical Cookies</h3>Analytical cookies allow us to
                improve the design and functionality of our Site by collecting
                data on how you access our Site, for example data on the content
                you access, how long you stay on our Site, etc;
              </li>
              <li className="text-base font-medium text-[#484352]">
                <h3>Targeting Cookies</h3>Targeting cookies collect data on how
                you use the Site and your preferences. This allows us to
                personalize the information you see on our Site for you; and
              </li>
              <li className="text-base font-medium text-[#484352]">
                <h3>Third-party Cookies</h3>Third-party cookies are created by a
                website other than ours. We may use third-party cookies to
                achieve the following purposes:
                <ol
                  type="1"
                  start={1}
                  className="flex flex-col gap-y-3 list-decimal pl-6"
                >
                  <li className="text-base font-medium text-[#484352]">
                    Statistics and analytics purposes.
                  </li>
                </ol>
              </li>
            </ol>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              Additional Clauses
            </h3>
            <p className="text-base font-medium text-[#484352]">
              Our site may, from time to time, contain links to and from
              third-party websites. If you follow a link to any of these
              websites, please note that these websites have their own privacy
              policies and that we do not accept any responsibility or liability
              for these policies. Please check these policies before you submit
              any personal data to these websites.
            </p>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              Modifications
            </h3>
            <p className="text-base font-medium text-[#484352]">
              This Privacy Policy may be amended from time to time in order to
              maintain compliance with the law and to reflect any changes to our
              data collection process. When we amend this Privacy Policy we will
              update the {`"Effective Date"`} at the top of this Privacy Policy.
              We recommend that our users periodically review our Privacy Policy
              to ensure that they are notified of any updates. If necessary, we
              may notify users by email of changes to this Privacy Policy.
            </p>
          </div>

          <div className="flex flex-col gap-y-4">
            <h3 className="text-base font-bold text-[#020C01]">
              Contact Information
            </h3>
            <p className="text-base font-medium text-[#484352]">
              If you have any questions, concerns or complaints, you can contact
              our privacy officer, at{` `}
              <a
                href="mailto:hello@vitract.com"
                className="underline text-[#FF713F]"
              >
                hello@vitract.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
